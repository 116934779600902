import type {Controller, Result} from 'bernie-core';
import type {PageData} from 'bernie-http';
import {AssistantStore} from 'src/stores';

import {codeSplit} from 'bernie-core';
import {PATHS} from 'src/shared/constants';
import React from 'react';

// head components
import {AnalyticsDataLayer, AppEnvironment} from 'src/components/analyticsDataLayer';
import {EdapOptions} from '../components/edapOptions';
import {EDAP} from '../components/edap';
import {StabAnalyticsPolyfill} from '../components/stabAnalyticsPolyfill';
import {AdvancedOptions} from '@escapia/bernie-plugin-advanced-controller';
import {ScriptTag} from '../components/ScriptTag';

export class SelectAgencyController implements Controller {
    public pageId = 'selectAgency';
    public path = '/:prefix?/:internal?/selectAgency';
    public routeName = 'app';
    public bundles = [];
    public caseSensitive = false;

    /**
     * Components may be referred to directly
     *   `public component = ExamplePage;`
     *
     * Or, the preferred option is to create a code split point that will generate a new bundle.
     *   Use webpack's magic comments `webpackChunkName: "INSERT_FRIENDLY_NAME_HERE"`
     *   This will give it a friendly name for the name of the file.
     */
    public component = codeSplit(() => import(/* webpackChunkName: "login" */ '../views/login'));
    public exact = true;

    public async fetch(): Promise<Result> {
        return {};
    }

    public fetchPageData(): Promise<PageData> {
        return Promise.resolve({title: 'Escapia', pageName: 'selectAgency'});
    }

    public async loadData({request, response, stores}: AdvancedOptions) {
        const store = stores.get<AssistantStore>('assistant');
        const isInternal = request.path.includes('/internal/');
        const promises = [store.reduceSystemData(), store.reduceTranslations()];

        if (isInternal) {
            const req = store.getRequest();
            if (!req?.auth?.isAuthenticated || req?.auth?.strategy !== 'okta') {
                response.redirect(302, PATHS.INTERNAL_LOGIN);
            }
            promises.push(store.reduceAllAgencyData());
        }

        return Promise.allSettled(promises).then(() => Promise.resolve({}));
    }

    public getHeadComponents({request, stores}: AdvancedOptions) {
        const store = stores.get<AssistantStore>('assistant');
        return [
            () => (
                <AnalyticsDataLayer
                    appversion={store.system?.appVersion}
                    publicuuid={-1}
                    monikerbrand={'hasp_escapia'}
                    analyticsbrand={'hasp_escapia'}
                    appname={'escapia-bernie-experience'}
                    appenvironment={store.system.environment as AppEnvironment}
                    pagetype={'escapiadash'}
                    pageflow={-1}
                    pagename={request.url}
                    visitortype={'property manager'}
                    sensitive={false}
                    mpaasregion={-1}
                    displaylocale={store.system?.locale ?? 'en_US'}
                />
            ),
            () => <EdapOptions skipPageview skipTagManager skipGA />,
            () => <EDAP />,
            () => <StabAnalyticsPolyfill />,
            () => (
                <ScriptTag
                    id="csrf"
                    script={`
                    window.ha = window.ha || {};
                    window.ha.csrfToken = '${
                        request?.extensions?.plugins?.['@catalyst/hapi-jwt-csrf']?.jwtCsrfTokens?.header ?? ''
                    }';`}
                />
            )
        ];
    }
}
